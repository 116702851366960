var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"uk-grid-collapse",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand@s uk-first-column d-flex align-item-center"},[_c('small',[_c('img',{attrs:{"src":_vm.logoFooter,"width":"120","alt":""}}),_vm._v(" © 2023 "),_c('strong',[_vm._v("Softinya")]),_vm._v(". Tüm Hakları Saklıdır. ")])]),_c('div',{staticClass:"uk-width-auto@s"},[_c('nav',{staticClass:"footer-nav-icon"},[(_vm.subdomain !== 'next4bizacademy.com')?_c('ul',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]):_c('ul',[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://tr.linkedin.com/company/anzeraogrenmeteknolojileri/","target":"_blank"}},[_c('i',{staticClass:"icon-brand-linkedin"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.instagram.com/anzeraofficial/","target":"_blank"}},[_c('i',{staticClass:"icon-brand-instagram"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://tr-tr.facebook.com/pages/category/Computer-Company/Anzera-%C3%96%C4%9Frenme-Teknolojileri-601130579942407/","target":"_blank"}},[_c('i',{staticClass:"icon-brand-facebook"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCYlNDP2rbHqxD95wbsOSyKw","target":"_blank"}},[_c('i',{staticClass:"icon-brand-youtube"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.linkedin.com/company/next4biz/","target":"_blank"}},[_c('i',{staticClass:"icon-brand-linkedin"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.instagram.com/next4biz/","target":"_blank"}},[_c('i',{staticClass:"icon-brand-instagram"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.facebook.com/next4biz","target":"_blank"}},[_c('i',{staticClass:"icon-brand-facebook"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.youtube.com/@next4biz","target":"_blank"}},[_c('i',{staticClass:"icon-brand-youtube"})])])
}]

export { render, staticRenderFns }