import Vue from "vue";
import VueI18n from "vue-i18n";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes", isCompression: true });

Vue.use(VueI18n);

let locale = "tr";
try {
  // set the current language for i18n.
  locale = ls.get("language");

  if (!locale) {
    locale = "tr";
    ls.set("language", "tr");
  }
} catch (e) {
  window.localStorage.clear();
}

function getTranslations() {
  return ApiService.get(`/api/localization`);
}

function prepareConfig(data) {
  let messages = {};
  let langauges = data.languages;
  ls.set("languages", JSON.stringify(langauges));
  langauges.forEach(function(currentValue) {
    Vue.set(messages, currentValue.code, data.translations[currentValue.code]);
  });

  moment.locale(locale, {
    relativeTime: {
      future: "%s sonra",
      past: "%s önce",
      s: "%d saniye",
      m: "%d dakika", //change that to "%d minute"
      mm: "%d dakika",
      h: "%d saat", //here too
      hh: "%d saat",
      d: "%d gün", //and here
      dd: "%d gün",
      M: "%d ay", //and so on...
      MM: "%d ay",
      y: "%d yıl",
      yy: "%d yıl",
    },
  });
  // console.log("this", this.locale);
  // Create VueI18n instance with options
  return new VueI18n({
    locale: locale,
    messages, // set locale messages
    fallbackLocale: "tr",
  });
}

export { prepareConfig, getTranslations };
