<template>
  <!-- side nav-->
  <div id="SideNav" class="side-nav uk-animation-slide-left-medium">
    <div class="side-nav-bg"></div>
    <!-- logo -->
    <div class="logo uk-visible@s">
      <router-link to="/dashboard">
        <i class="uil-home-alt"></i>
      </router-link>
    </div>
    <ul>
      <li class="d-block d-sm-none">
        <router-link to="/dashboard">
          <i class="uil-home-alt"></i><span class="tooltips"> {{ $t("sideNav.Login") }}</span>
        </router-link>
      </li>
      <!-- <li>
        <a href="#">
          <i class="uil-book-open"></i
          ><span class="tooltips"> {{ $t("sideNav.My_education") }}</span></a
        >
        <div class="side-menu-slide">
          <div class="side-menu-slide-content">
            <ul data-simplebar>
              <li>
                <router-link :to="{name: 'user.my-education'}">
                  <i class="uil-book-open"></i> {{ $t("sideNav.My_education") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'user.my-assignment'}">
                  <i class="uil-book-open"></i>
                  {{ $t("sideNav.my_assigned_education") }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </li> -->

      <li>
        <router-link :to="{ name: 'user.my-assignment' }">
          <i class="uil-book-open"></i><span class="tooltips"> {{ $t("sideNav.My_education") }}</span>
        </router-link>
      </li>

      <li>
        <router-link to="/search">
          <i class="uil-search-alt"></i><span class="tooltips"> {{ $t("sideNav.Search") }}</span>
        </router-link>
      </li>

      <li>
        <router-link to="/my-statistics">
          <i class="uil-chart-line"></i>
          <span class="tooltips"> {{ $t("sideNav.My_statistics") }}</span>
        </router-link>
      </li>

      <!-- <li>
        <a href="#">
          <i class="uil-bolt-alt"></i
          ><span class="tooltips"> {{ $t("sideNav.My_duels") }}</span></a
        >
        <div class="side-menu-slide">
          <div class="side-menu-slide-content">
            <ul data-simplebar>
              <li>
                <router-link to="/duel">
                  <i class="uil-user-plus"></i> {{ $t("sideNav.Duel_Square") }}
                </router-link>
              </li>
              <li>
                <router-link to="/duel-result">
                  <i class="uil-clipboard-notes"></i>
                  {{ $t("sideNav.My_Duel_Results") }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </li> -->

      <!-- <li>
          <a href="#"><i class="uil-presentation-edit"></i><span class="tooltips"> Sınıf İçi Etkileşimler</span> </a>
      </li> -->

      <li v-if="CollectionVisible">
        <router-link to="/my-collection">
          <i class="uil-notebooks"></i><span class="tooltips"> {{ $t("sideNav.My_collections") }}</span>
        </router-link>
      </li>

      <li v-if="this.$isCatalogTrainingVisible">
        <router-link to="/education-category">
          <i class="uil-books"></i>
          <span class="tooltips"> {{ $t("sideNav.Catalog_education") }}</span>
        </router-link>
      </li>

      <li v-if="this.$isGiftVisible">
        <router-link :to="{ name: 'User.gift' }">
          <i class="uil-gift"></i>
          <span class="tooltips"> {{ $t("sideNav.Gift_shop") }}</span>
        </router-link>
      </li>

      <li v-if="socialPostVisible">
        <router-link :to="{ name: 'User.wall' }">
          <i class="uil-coffee"></i>
          <span class="tooltips"> {{ $t("sideNav.Wall") }}</span></router-link>
      </li>

      <li>
        <router-link to="/agenda">
          <i class="uil-calendar-alt"></i>
          <span class="tooltips"> {{ $t("sideNav.Agent") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideNav",
};
</script>

<style scoped></style>
