<template>
  <div class="container">
    <div class="footer">
      <div class="uk-grid-collapse" uk-grid>
        <div class="uk-width-expand@s uk-first-column d-flex align-item-center">
          <small><img :src="logoFooter" width="120" alt=""> © 2023 <strong>Softinya</strong>. Tüm Hakları Saklıdır.
          </small>
        </div>
        <div class="uk-width-auto@s">
          <nav class="footer-nav-icon">
            <ul v-if="subdomain !== 'next4bizacademy.com'">
              <li><a href="https://tr.linkedin.com/company/anzeraogrenmeteknolojileri/" target="_blank"><i
                    class="icon-brand-linkedin"></i></a></li>
              <li><a href="https://www.instagram.com/anzeraofficial/" target="_blank"><i
                    class="icon-brand-instagram"></i></a></li>
              <li><a
                  href="https://tr-tr.facebook.com/pages/category/Computer-Company/Anzera-%C3%96%C4%9Frenme-Teknolojileri-601130579942407/"
                  target="_blank"><i class="icon-brand-facebook"></i></a></li>
              <li><a href="https://www.youtube.com/channel/UCYlNDP2rbHqxD95wbsOSyKw" target="_blank"><i
                    class="icon-brand-youtube"></i></a></li>
            </ul>
            <ul v-else>
              <li><a href="https://www.linkedin.com/company/next4biz/" target="_blank"><i
                    class="icon-brand-linkedin"></i></a></li>
              <li><a href="https://www.instagram.com/next4biz/" target="_blank"><i class="icon-brand-instagram"></i></a>
              </li>
              <li><a href="https://www.facebook.com/next4biz" target="_blank"><i class="icon-brand-facebook"></i></a>
              </li>
              <li><a href="https://www.youtube.com/@next4biz" target="_blank"><i class="icon-brand-youtube"></i></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    showMenu: {
      required: false,
      default: true
    },
  },
  data() {
    return {
      subdomain: null
    }
  },
  computed: {
    logoFooter() {
      return process.env.BASE_URL + "media/images/new-logo-dark.svg";
    }
  },
  mounted() {
    this.subdomain = window.location.hostname
  },
}
</script>

<style scoped>
.footer small {
  font-weight: 300;
}
</style>